// import { Counter } from './Counter';
// import { IBAN } from './IBAN';
// import { Obstliste } from './Obstliste';
// import { Kennzeichen } from './Kennzeichen';
// import { Message } from './Message';
// import { TicTacToe } from './TicTacToe';
// import { Exchange } from './Exchange';
import { Pokedex } from './Pokedex';

export default function App() {
  return (
    <div className="container mt-3">
      {/*
        <IBAN />
        <Obstliste />
        <Kennzeichen />
        <Counter />
        <Message />
        <TicTacToe />
        <Exchange />
      */}
      <Pokedex />
    </div>
  );
}
